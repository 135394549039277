import "../styles/login.scss";
import { CompaniesCard } from "../components/login-component.js";
import Cookie from "../modules/cookies-class.js";
import { login } from "../modules/auth-module.js";
import { Toast } from "../modules/global.js";

// Define components
customElements.define("companies-card", CompaniesCard);

if (Cookie.get("token")) {
    window.location.href = "../pages/dashboard.html";
}

// Change password input type and icon
const passwordInput = document.querySelector("#password");
const usernameInput = document.querySelector("#username");
const passwordAddon = document.querySelector("#password-addon");

passwordAddon.addEventListener("click", () => {
    if (passwordInput.type === "password") {
        passwordInput.type = "text";
        passwordAddon.innerHTML = '<i class="ri-eye-off-fill align-middle"></i>';
    } else {
        passwordInput.type = "password";
        passwordAddon.innerHTML = '<i class="ri-eye-fill align-middle"></i>';
    }
});

// Hide Login and Show companies
const hideBtn = document.querySelector("#hide-login");
const logins = document.querySelector("#logins");
const companies = document.querySelector("#companies");
const backToLogin = document.querySelector("#back-to-login");

hideBtn.addEventListener("click", () => {
    logins.classList.add("d-none");
    companies.classList.remove("d-none");
});

backToLogin.addEventListener("click", () => {
    logins.classList.remove("d-none");
    companies.classList.add("d-none");
});

// Get all radios in company-wrapper and activate the clicked radio
const companyWrapper = document.querySelector("#company-wrapper");
const radios = companyWrapper.querySelectorAll("#company-wrapper > div > .card > .radio-shape");
const cards = companyWrapper.querySelectorAll("#company-wrapper > div > .card");
const loginBtn = document.querySelector("#login-btn");

cards.forEach((card, index) => {
    card.addEventListener("click", () => {
        cards.forEach((card) => {
            card.classList.remove("active-card");
        });
        radios.forEach((radio, i) => {
            if (i === index) {
                radio.classList.add("active");
            } else {
                radio.classList.remove("active");
            }
        });

        Cookie.set("selectedCompany", card.getAttribute("id"));
        card.classList.add("active-card");
    });
});

loginBtn.addEventListener("click", () => {
    const selectedCompany = Cookie.get("selectedCompany");
    const username = usernameInput.value;
    const password = passwordInput.value;
    
    const { loginMutation } = login(username, password, selectedCompany);
    if (selectedCompany && username && password) {
        void loginMutation();
    } else {
        Toast("error", "Compila tutti i campi");
    }
});
