import { companies } from "../modules/global";

export class CompaniesCard extends HTMLElement {
    constructor() {
        super();

        const companyCards = companies.map((company) => {
            return `
                <div class="col-lg-6 mb-4">
                    <div role="button" class="card overflow-hidden p-lg-2 p-2" id="${company.value}">
                        <div class="radio-shape"><span></span></div>
                        <img loading="lazy" decoding="async" width="0" height="0" src="${company.image}" alt="logo" class="company-images">
                    </div>
                </div>
            `;
        });

        this.innerHTML = `
        <div class="row" id="company-wrapper">
            ${companyCards.join("")}
        </div>`;
    }
}